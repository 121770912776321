/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import { ModalConfirm } from '../ModalConfirm';
import { useAuth0 } from '@auth0/auth0-react';
import './styles.scss';

export interface IHeaderProps {
}

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`header.${key}`)
  
  const [shownModalConfirmLogout, setShownModalConfirmLogout] = useState<boolean>(false); // false

  const { logout } = useAuth0();

  return (
    <>
      <header className={`header`}>
        <div className="left-logo">
          <a href="/" className='icons btn-logo'>
          </a>
        </div>
        <div className="right-link flex">
          <ul className="lists">
            
          </ul>
          <a className='btn-logout' onClick={() => setShownModalConfirmLogout(true)}>
            <i className='icons icon-logout'></i>
            <span className='txt'>
              {t('logout')}
            </span>
          </a>
        </div>
      </header>      
      {shownModalConfirmLogout && (        
        <ModalConfirm
          title={'logout'}
          description={'are_you_sure_to_log_out'}
          cancelLabel={'cancel'}
          confirmLabel={'logout'}
          onClose={() => {
            setShownModalConfirmLogout(false);
          }}
          onConfirm={() => {
            setShownModalConfirmLogout(false);
            logout({ logoutParams: { returnTo: window.location.origin }});
          }}
        />
      )}
    </>
  );
};

export default Header;
