import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export interface IDownloadCsvButtonProps {
  onClickDownload: () => {};
}

export const DownloadCsvButton: React.FunctionComponent<
  IDownloadCsvButtonProps
> = ({ onClickDownload }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`analyseResultPage.${key}`);

  return (
    <button className="btn download-btn btn-border" onClick={onClickDownload}>
      <i className="icons icon-saveload-blue"></i>
      {t('to_csv')}
    </button>
  );
};

export default DownloadCsvButton;
