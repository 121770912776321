/* eslint-disable jsx-a11y/anchor-is-valid */
import { toast } from 'react-toastify';
import {useTranslation} from 'react-i18next';
import nprogress from 'accessible-nprogress';
import { useQuery } from '@tanstack/react-query';
import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker"
import _ from 'lodash';

import "react-datepicker/dist/react-datepicker.css";

import './styles.scss';
import { delay } from '../../../utils';
import DataSvc from '../../../services/dataSvc';
import { ModalConfirm } from '../../ModalConfirm';
import { ICategory, sortCategories } from '../../../models/ICategory';
import { Checkbox } from '../../FormElement/Checkbox';
import DropdownSelect from '../../FormElement/DropdownSelect';
import
{
  ILocation,
  IAnalyseAddFormData,
  ISnsType,
  IMunicipalityGroup,
  IMunicipality,
} from '../../../models';
import ItemCondition from '../../ItemCondition';

export interface IModalNewAnalysisProps {
  onClose: () => void;
  onSave: (formData: IAnalyseAddFormData) => void;
}

export const ModalNewAnalysis: React.FunctionComponent<IModalNewAnalysisProps> = (props) => {
  const { onClose, onSave } = props;
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`modalNewAnalysis.${key}`)

  const [selectedLocations, setSelectedLocations] = useState<ILocation[]>([])
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);
  const [currentDropdownLocation, setCurrentDropdownLocation] = useState<ILocation | null>(null);
  const [formData, setFormData] = useState<IAnalyseAddFormData>({
    municipalities: [],
    categories: [],
    locations: null,
    languageJa: true,
    languageEn: true,
    start_date: null,
    end_date: null,
  });

  const [categoriesData, setCategoriesData] = useState<ICategory[]>([]);
  const [municipalitiesData, setMunicipalitiesData] = useState<
    IMunicipalityGroup[]
  >([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectAll, setSelectAll] = useState<any>({
    municipalities: [false, false],
    categories: false,
  });

  useEffect(() => {
    nprogress.configure({parent: '.modal-mains'});
  }, []);

  const { data: locations } = useQuery([`locations`], async () => {
    try {
      nprogress.start();
      const res = await DataSvc.getLocationsData([]);
      nprogress.done();
      return res
    } catch(e) {
      toast.error(`${_t('error.fetching_locations')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });

      await delay(5000);
      return [];
    }
  });

  const { data: categories } = useQuery(['categories'], async () => {
    try {
      return await DataSvc.getCategoriesData();
    } catch(e) {
      toast.error(`${_t('error.fetching_categories')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });

      await delay(5000);
      return [];
    }
  })

  const { data: municipalities } = useQuery(['municipalities'], async () => {
    try {
      return await DataSvc.getMunicipalitiesData();
    } catch(e) {
      toast.error(`${_t('error.fetching_municipalities')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });

      await delay(5000);
      return [];
    }
  })
  
  useEffect(() => {
    if (municipalities) {
      const prefArray = municipalities.filter((item) => {
        return item.type === 'pref';
      });

      const selectAllTemp = selectAll;
      selectAllTemp.municipalities = [];
      const municipalitiesArray:IMunicipalityGroup[] = [];

      prefArray.forEach((itemPref) => {
        const muniArray = municipalities.filter((itemMuni) => {
          return (
            itemMuni.type === 'muni' &&
            itemMuni.parent_external_id === itemPref.external_id
          );
        });

        municipalitiesArray.push({
          name: itemPref.name,
          muniList: muniArray,
        });
        selectAllTemp.municipalities.push(false);
      });

      setMunicipalitiesData(_.cloneDeep(municipalitiesArray));
      setSelectAll(_.cloneDeep(selectAllTemp));
    }
    // eslint-disable-next-line
  }, [municipalities]);

  useEffect(() => {
    if (categories) {
      const sortedCategories = sortCategories(categories);
      setCategoriesData(sortedCategories);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  // is Enabled Btn
  const shouldEnableButton = () => {
    return (
      (
        !!formData.locations ||
        categoriesData?.some(item => item.checked) ||
        municipalitiesData?.some(group => group.muniList.some(item => item.checked))
      )
      && (formData.languageJa || formData.languageEn)) 
      &&  ((!startDate && !endDate) || (startDate && endDate))
  }

  const renderSelectedLocation = (location: ILocation) => {
    return(
    <div key={location.id}
         className='slected-location-item selected'
         onClick={() => {
           const filterLocations = selectedLocations.filter(l => l.id !== location.id);
           setSelectedLocations(filterLocations)
           setFormData({
            ...formData,
            locations: filterLocations
          })
         }}
    >
      {location.name}
    </div>)
  }

  // change Checkbox
  const changeCheckbox = (value: boolean, index: number, name: string) => {
    switch (name) {
      // NOTE: Switch used for easy adaptation if more checkboxes are brought in (eg from AC)
      case 'categories':
        const categoriesDataTemp = categoriesData;
        categoriesDataTemp[index].checked = value;
        setCategoriesData(_.cloneDeep(categoriesDataTemp));
        break;
    }
  };

    // change Select All
    const changeSelectAll = (value: boolean, name: string) => {
      const selectAllTemp = selectAll;
      switch (name) {
        // NOTE: Switch used for easy adaptation if more checkboxes are brought in (eg from AC)
        case 'categories':
          selectAllTemp.categories = value;
          const categoriesDataTemp = categoriesData;
          categoriesDataTemp.forEach((item, index) => {
            item.checked = value;
          });
          setCategoriesData(_.cloneDeep(categoriesDataTemp));
          break;
      }
      setSelectAll(_.cloneDeep(selectAllTemp));
    };

  // change Checkbox Municipality
  const changeCheckboxMunicipality = (
    value: boolean,
    index: number,
    indexMunicipalityGroup: number
  ) => {
    const municipalitiesDataTemp = municipalitiesData;
    municipalitiesDataTemp[indexMunicipalityGroup].muniList[index].checked =
      value;
    setMunicipalitiesData(_.cloneDeep(municipalitiesDataTemp));
  };

  // change Select All Municipality
  const changeSelectAllMunicipality = (
    value: boolean,
    indexMunicipalityGroup: number
  ) => {
    const selectAllTemp = selectAll;

    selectAllTemp.municipalities[indexMunicipalityGroup] = value;
    const municipalitiesDataTemp = municipalitiesData;
    municipalitiesDataTemp[indexMunicipalityGroup].muniList.forEach(
      (item: IMunicipality, index: number) => {
        item.checked = value;
      }
    );
    setMunicipalitiesData(_.cloneDeep(municipalitiesDataTemp));
    setSelectAll(_.cloneDeep(selectAllTemp));
  };

  const startSave = (formData: IAnalyseAddFormData) => {
    const formDataHolder = { ...formData };
    formDataHolder.municipalities = municipalitiesData
      .flatMap(muniGroup => muniGroup.muniList)
      .filter(item => item.checked)
      .map(({ checked, ...otherFields }) => otherFields);
    formDataHolder.categories = categoriesData
      .filter(item => item.checked)
      .map(({ checked, ...otherFields }) => otherFields);

    onSave(formDataHolder);
  }

  return (    
    <div className="modal modal-default modal-new-analysis">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="modal-mains__main">
          <div className="top-title flex-grid">
            {t('new_analysis')}
          </div>
          <p>{t('individual_selection_will_be_prioritized')}</p>
          <hr />
          <div className="title-bar row-form mb60">
            <div className="top-title flex-grid">
              {t('select_by_filters')}
            </div>
            <div className="check-groups">
              <div className="blue-block-row flex">
                <div className="blue-block">{t('municipalities')}</div>
              </div>
              {municipalitiesData.map(
                (item: IMunicipalityGroup, indexMunicipalityGroup: number) => (
                  <React.Fragment key={indexMunicipalityGroup}>
                    <ItemCondition
                      title={item.name}
                      checkedAll={
                        selectAll.municipalities[indexMunicipalityGroup]
                      }
                      sectionName={item.name}
                      isBlueTitle={false}
                      dataList={item.muniList}
                      changeSelectAll={(checked: boolean) => {
                        changeSelectAllMunicipality(
                          checked,
                          indexMunicipalityGroup
                        );
                      }}
                      changeCheckbox={(checked: boolean, index: number) => {
                        changeCheckboxMunicipality(
                          checked,
                          index,
                          indexMunicipalityGroup
                        );
                      }}
                    />
                  </React.Fragment>
                )
              )}
            </div>
            <ItemCondition
              title={t('categories')}
              checkedAll={selectAll.categories}
              sectionName={'categories'}
              isBlueTitle={true}
              dataList={categoriesData}
              changeSelectAll={(checked: boolean, sectionName: string) => {
                changeSelectAll(checked, sectionName);
              }}
              changeCheckbox={(
                checked: boolean,
                index: number,
                sectionName: string
              ) => {
                changeCheckbox(checked, index, sectionName);
              }}
            />
          </div>
          <hr />
          <div className="title-bar row-form mb60">
            <div className="top-title flex-grid">
              {t('select_individually')}
            </div>
            <div className={'select-locations-container'}>
              <div className="label-title flex">
                {t('location')}
              </div>
              <button
                className={'btn btn-border select-all'}
                onClick={() => {
                  if(locations?.data) {
                    const idMap = new Map();
                    selectedLocations.forEach(location => idMap.set(location.id, location));
                    locations.data.forEach((location: { id: any; }) => idMap.set(location.id, location));
                    const unionList = Array.from(idMap.values());

                    setSelectedLocations(unionList)
                    setFormData({
                      ...formData,
                      locations: unionList
                    })
                  }
                }}
                >
                {t('select_all')}
              </button>
            </div>
            <DropdownSelect
              fieldLabel={''}
              value={currentDropdownLocation}
              isTranslation={false}
              items={locations?.data || []}
              placeholder={'select_a_single_location'}
              classNameContainer={'sort-dropdown'}
              onChange={(
                value: ILocation | ICategory | ISnsType
              ) => {
                const selectedLocationIds = selectedLocations.map(l => l.id);
                const valueId = typeof value.id === 'number' ? value.id : parseInt(value.id);
                if (!selectedLocationIds.includes(valueId)) {
                  selectedLocations.unshift(value as ILocation)
                  setSelectedLocations(selectedLocations)
                  setFormData({
                    ...formData,
                    locations: selectedLocations
                  })
                }

                setCurrentDropdownLocation(value as ILocation)
              }}
            />
            { selectedLocations.length > 0 &&
            <div>
              <div className="label-title flex">
              {t('selected_locations')}
              </div>
              <div className='selected-locations-container'>
                {selectedLocations.map(l => renderSelectedLocation(l as ILocation))}
              </div>
            </div>}
            <hr />
            <div className="top-title flex-grid">
              {t('additional_filters')}
            </div>
            <div className="label-title flex">
              {t('language')}
            </div>
            <div className="check-lang">
              <div className="items">
                <Checkbox
                  label={t('japanese')}
                  checked={formData.languageJa}
                  id={'id-languageJa'} 
                  onChange={function (checked: boolean): void {
                    setFormData({
                      ...formData,
                      languageJa: checked
                    })
                  }} />
              </div>
              <div className="items">
                <Checkbox
                  label={t('english')}
                  checked={formData.languageEn}
                  id={'id-languageEn'} 
                  onChange={function (checked: boolean): void {
                    setFormData({
                      ...formData,
                      languageEn: checked
                    })
                  }} />
              </div>
            </div>
            
            <div className="label-title flex">
              {t('date_range')}
            </div>
            <div className="select-date-picker-container">
            <DatePicker
              className='input-date-picker'
              selected={startDate}
              onChange={(date) => {
                setStartDate(date as Date)
                setFormData({
                  ...formData,
                  start_date: date ? dayjs(date).format('YYYY-MM-DD') : null
                })
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={new Date("2011-01-01")}
              maxDate={new Date()}
              isClearable={true}
            />
            ~
            <DatePicker
              className='input-date-picker'
              selected={endDate}
              onChange={(date) => {
                setEndDate(date as Date)
                setFormData({
                  ...formData,
                  end_date: date ? dayjs(date).format('YYYY-MM-DD') : null
                })
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate || new Date("2011-01-01")}
              maxDate={new Date()}
              isClearable={true}
            />
            </div>
          </div>
          
          <div className="bottom-btns">
            <a className='btn btn-border'
              onClick={() => {
                onClose()
              }}>
              {t('cancel')}
            </a>
            <a className={`btn btn-blue ${!shouldEnableButton() ? 'disabled' : ''}`}
              onClick={() => {
                setShownModalConfirm(true);
              }}>
              {t('start')}
            </a>
          </div>
        </div>
      </div>
      
      {shownModalConfirm && (        
        <ModalConfirm
          title={'please_confirm_to_add_new_analysis'}
          cancelLabel={'cancel'}
          confirmLabel={'confirm'}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            startSave(formData)
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalNewAnalysis;
