import React, { useState, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import LineChartItem from './LineChartItem';
import BarChartItem from './BarChartItem';
import { OrgDailyMetricsData } from '../../../models/MetricsLineChartData';
import
{
  IStats,
} from '../../../models';
import './styles.scss';

export interface IStatsProps {
  analysisStats: IStats;
}

export const Stats: React.FunctionComponent<IStatsProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`analyseResultPage.${key}`)

  const { analysisStats } = props;
  
  const colorArrayBar = ['#debdff','#b89cff','#907dff','#675eff','#3241ff'];
  const colorArrayLine = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe'];

  const [dataListBarChart, setDataListBarChart] =
    useState<OrgDailyMetricsData>({
      title: '',
      dataSources: [],
      dataForLine: [],
    });
  
  const [dataListLineChart, setDataListLineChart] =
    useState<OrgDailyMetricsData>({
      title: '',
      dataSources: [],
      dataForLine: [],
    });
  
  useEffect(() => {
    if (analysisStats) {      
      const dataListBarChartTemp: any = {
        title: '',
        dataSources: [
          {
            label: '1',
            fieldName: '1',
            style: 'solid',
            color: colorArrayBar[0]
          },
          {
            label: '2',
            fieldName: '2',
            style: 'solid',
            color: colorArrayBar[1]
          },
          {
            label: '3',
            fieldName: '3',
            style: 'solid',
            color: colorArrayBar[2]
          },
          {
            label: '4',
            fieldName: '4',
            style: 'solid',
            color: colorArrayBar[3]
          },
          {
            label: '5',
            fieldName: '5',
            style: 'solid',
            color: colorArrayBar[4]
          }
        ],
        dataForLine: [],
      };
      
      if (analysisStats.by_rating && analysisStats.by_rating.length > 0) {
        let dotData: any = {
          name: ''
        }
        
        // eslint-disable-next-line
        analysisStats.by_rating.forEach((item, index: number) => {          
          const label = `${item.year}年 Q${item.quarter}`
          dotData = {
            ...dotData,
            label: label,
          };
          
          let totalCount = 0;
          for (let i = 0; i < analysisStats.by_rating[0].rate_and_count.length; i++) {          
            totalCount += item.rate_and_count[i].count;
          }

          if (totalCount === 0) {
            dataListBarChartTemp.dataForLine.push({label: label, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0});
            return;
          }
          
          let rate5Count = 100;
          for (let i = 0; i < analysisStats.by_rating[0].rate_and_count.length - 1; i++) {          
            dotData[`${i + 1}`] = Math.floor(item.rate_and_count[i].count / totalCount * 100);
            rate5Count -= dotData[`${i + 1}`];
          }
          
          dotData[`5`] = rate5Count;
          
          dataListBarChartTemp.dataForLine.push(dotData)
        })
      }
      
      setDataListBarChart(dataListBarChartTemp);
      
      const dataListLineChartTemp: any = {
        title: '',
        dataSources: [],
        dataForLine: [],
      };
      
      dataListLineChartTemp.dataSources = analysisStats.by_language.map((item: any, index: number) => {
        return {
          label: item.language,
          fieldName: item.language,
          style: 'solid',
          color: colorArrayLine[index]
        }
      });
      
      if (analysisStats.by_language.length > 0) {
        let dotData: any = {
          name: ''
        }
        for (let i = 0; i < analysisStats.by_language[0].series.length; i++) {          
          // eslint-disable-next-line
          analysisStats.by_language.forEach((item, index: number) => {          
            const label = `${item.series[i].year}年 Q${item.series[i].quarter}`
            dotData = {
              ...dotData,
              label: label,
              [item.language]: item.series[i].count
            };
          })
          
          dataListLineChartTemp.dataForLine.push(dotData)
        }
      }
      
      setDataListLineChart(dataListLineChartTemp);
    }
    // eslint-disable-next-line
  }, [analysisStats]);

  return (
    <div className="result-stats">
      <h4 className='title'>
        {t('stats.title')}
        <span className="line"></span>
      </h4>
      <div className="stats-content">
        <div className="row">
          <div className="col col-md-6 col-xs-12">
            <div className="charts bar-chart">
              <span className="chart-title">{t('stats.bar_chart_title')}</span>
              <BarChartItem
                width={480}
                height={280}
                dataList={dataListBarChart}
                />
            </div>
          </div>
          <div className="col col-md-6 col-xs-12">
            <div className="charts line-chart">
              <span className="chart-title">{t('stats.line_chart_title')}</span>
              <LineChartItem
                width={480}
                height={280}
                dataList={dataListLineChart}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
