// import * as types from '../constants/actionTypes';

const defaultState = {
  db: {},
};

const exportReducer =  (state = defaultState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default exportReducer;