import { ChangeEvent } from 'react';

// form Validation service
export default class FormValidationSvc {  
  // validate the pattern input entering
  static validateInputEnteringPattern(event: ChangeEvent<HTMLInputElement>, fieldNameValueCurrent?: string) {    
    return event.target.validity.valid
      ? event.target.value.toString()
      : (fieldNameValueCurrent || '').toString()
  }
  
  // replace English And Full Width char
  static replaceEnglishAndFullWidthChar(event: ChangeEvent<HTMLInputElement>) {    
    return event.target.value.toString().replace(/[X]/g,'');
  }
  
  // check Email format
  static checkEmail(strValue: string) {
    if (strValue === '') {
      return true
    }
    const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (filter.test(strValue)) {
      return true
    } else {
      return false
    }
  }
  
  static checkPassword(strValue: string) {
    if (strValue === '') {
      return true
    }
    const filter = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*])[a-zA-Z\d!"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]{8,20}$/;

    return filter.test(strValue);
  }
  
  static checkKanaName(strValue: string) {
    if (strValue === '') {
      return true
    }
    const filter = /^[ァ-ヶー ]*$/;

    return filter.test(strValue);
  }
  
  // check Postcode format
  static checkPostcode(strValue: string) {
    if (strValue === '') {
      return true
    }
    const filter = /^\d{3}-\d{4}$/;

    if (filter.test(strValue)) {
      return true
    } else {
      return false
    }
  }
  
  // check Phone format
  static checkPhone(strValue: string) {
    if (strValue === '') {
      return true
    }
    const filter = /^\d{3}-\d{3}-\d{4}$/;

    if (filter.test(strValue)) {
      return true
    } else {
      return false
    }
  } 
  
  // check Start With Letter And Number
  static checkStartWithLetterAndNumber(strValue: string) {
    if (strValue === '') {
      return true
    }
    const filter = /^[a-z0-9]+[-_a-z0-9]*$/;

    if (filter.test(strValue)) {
      return true
    } else {
      return false
    }
  }
}
