/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useTranslation} from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import
{
  ISnsType,
  ILocation,
  ICategory,
} from '../../../models';
import './styles.scss';

export interface DropdownSelectProps {
  fieldLabel?: string;
  isShownError?: boolean;
  isTranslation?: boolean;
  errorText?: string;
  tipsLabel?: string;
  isShownTips?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  value: ILocation | null | ISnsType | ICategory;
  items: ILocation[] | ISnsType[];
  classNameContainer?: string;
  onChange: (value: ILocation | ISnsType | ICategory) => void;
}

const DropdownSelect: React.FunctionComponent<DropdownSelectProps> = (
  props: DropdownSelectProps,
) => {  
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`common.dropdown.${key}`)

  const { fieldLabel, isShownError, isTranslation, errorText, tipsLabel, isShownTips, isRequired, placeholder, isDisabled,
          value, items, classNameContainer } = props;
  /**
   * On click item
   * @param value the item's value
   */
  const onClickItem = (value: ILocation | ISnsType | ICategory) => {
    props.onChange(value);
  };

  return (
    <div>
      <div className="label-txt">
        {fieldLabel} { isRequired && (<em>*</em>)}
        {isShownTips && (
          <a className="icons icon-info"
            title={tipsLabel}
            onClick={(event) => {
              event.preventDefault()
            }}>
          </a>
        )}
      </div>
      <div className="inputs-wrap">
        <div className={`inputs ${isShownError ? 'error' : ''}`}>
          <Dropdown className={`dropdown-select ${classNameContainer ? classNameContainer : ''}`}>
            <Dropdown.Toggle variant="outline"
              disabled={isDisabled}>
              <div className={`toggle-label ${value ? '' : 'placeholder-label'}`}>
                {isTranslation ? t(value?.name || (placeholder || 'select')) : (value?.name || t(placeholder || 'select'))}
              </div>
              <span/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {items.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  as="button"
                  className={value === item ? 'selected' : ''}
                  onClick={() => onClickItem(item)}>
                  {isTranslation ? t(item.name) : item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isShownError && (
          <div className="bottom-error-txt">
            <i className="icons icon-error"></i>
            <span className="txt">
              {errorText}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownSelect;
