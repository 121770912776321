/*
 * App configurations
 */

export const META = {
  PAGE_TITLE_SUFFIX: 'Kuchikomi Compass Web resources',
  PAGE_DESCRIPTION: 'Kuchikomi Compass Web resources',
  PAGE_KEYWORDS: 'Kuchikomi Compass Web resources',
};

export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const STATUS_POLL_INTERVAL = 15000;  // 15000

export const API_BASE = process.env.REACT_APP_API_BASE || 'http://127.0.0.1:4010';
// export const API_BASE = 'http://localhost:4010';

export const AUTH0 = {
  DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || ''
};