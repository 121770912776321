import 'babel-polyfill';
import 'whatwg-fetch';
import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import {Routes, Route, BrowserRouter } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';

import allReducers from './reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';
import 'accessible-nprogress/dist/accessible-nprogress.min.css';

import './i18n';
import AnalysesPage from './containers/AnalysesPage';
import AnalysisResultPage from './containers/AnalysisResultPage';
import Auth0ProviderWithNavigation from './components/Auth0ProviderWithHistory';
import { AUTH0 } from './config';

const middlewares = [thunk];

// Only use the redux-logger middleware in development
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(createLogger());
}

const store = createStore(allReducers, applyMiddleware(...middlewares));

const container = document.getElementById('root');

// create a root
const root = createRoot(container!);

const providerConfig = {
  domain: AUTH0.DOMAIN,
  clientId: AUTH0.CLIENT_ID,
  redirectUri: window.location.origin,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Auth0ProviderWithNavigation {...providerConfig}>
              <Routes>
                <Route key={'/'} path={'/'} element={<ProtectedRoute component={AnalysesPage} />} />
                <Route key={'/analyses'} path={'/analyses'} element={<ProtectedRoute component={AnalysesPage} />} />
                <Route key={'/analyses/:uuid'} path={'/analyses/:uuid'} element={<ProtectedRoute component={AnalysisResultPage} />} />
              </Routes>
          </Auth0ProviderWithNavigation>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </Suspense>
);
