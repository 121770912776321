import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Pagination from '../../Pagination';
import StatusItem from '../../StatusItem';
import { DATE_FORMAT } from '../../../config';
import { format, utcToZonedTime } from 'date-fns-tz';
import
{
  ILocation,
  IAnalysisOverview,
  IAnalysesFilterFormData,
  IPost,
} from '../../../models';
import './styles.scss';
import { IGbpPost } from '../../../models/IGbpPost';

export interface IAnalysesListProps {
  dataList: IAnalysisOverview[];
  totalCount: number;
  filterFormData: IAnalysesFilterFormData;
  onChangeFilterFormData: (filterFormData: IAnalysesFilterFormData) => void
}

export const AnalysesList: React.FunctionComponent<IAnalysesListProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`analysesPage.${key}`)

  const { dataList, totalCount, filterFormData, onChangeFilterFormData } = props;

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <div className="white-border-panel">      
        <div className="table-data">
          <div className="row-th">
            <div className="col-th width25">
              <div className="spacing">
                {t('uuid')}
              </div>
            </div>
            <div className="col-th width11">
              <div className="spacing">
                {t('status')}
              </div>
            </div>
            <div className="col-th width16 ">
              <div className="spacing">
                {t('condition')}
              </div>
            </div>
            <div className="col-th width14 ">
              <div className="spacing">
                {t('period')}
              </div>
            </div>
            <div className="col-th width14 ">
              <div className="spacing">
                {_t('common.status.created_at').toString()}
              </div>
            </div>
            <div className="col-th width14 ">
              <div className="spacing">
                {_t('common.status.completed_at').toString()}
              </div>
            </div>
            <div className='col-th width11'>
              <div className="spacing">
                {t('number_of_reviews').toString()}
              </div>
            </div>
          </div>
          {
            (!dataList || dataList.length === 0) &&
            <div className="row-td text-center sub-txt">
              {t('no_analyses')}
            </div>
          }
          {
            (dataList.slice(0, filterFormData.numberPerPage) || []).map((item: IAnalysisOverview, index: number) => (
              <div className="row-td" key={index}>
                <div className="col-td width25">
                  <div className="spacing facility-title">
                    {item.status === 'completed' ? (
                      <NavLink to={`/analyses/${item.uuid}`} className='blue-link'>
                        {item.uuid}
                      </NavLink>
                    ) : (
                      <span className='blue-text'>
                        {item.uuid}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-td width11">
                  <div className="spacing">
                    <StatusItem
                      status={item.status}
                      />
                  </div>
                </div>
                <div className="col-td width16 ">
                  <div className="spacing">
                    <div className="top-location bold">
                      <div className="txt">
                        {
                          (item.condition?.locations &&  t('locations')) ||
                          (item.condition?.gbp_posts && t('gbp_posts')) ||
                          (item.condition?.posts && t('sns_posts'))
                        }
                      </div>
                      {
                        item.condition?.locations &&
                        item.condition.locations.map((itemLocation: ILocation, indexLocation: number) => (
                          <div className="bottom-txt" key={indexLocation}>
                            <i className='points'></i>
                            <span className='txt bold'>{itemLocation.name}</span>
                          </div>
                      ))}
                      {
                        item.condition?.posts && item.condition.posts.map((itemPost: IPost) => (
                          <div className="bottom-txt" key={itemPost.post_id}>
                            <i className='points'></i>
                            <span className='txt bold'>{`${itemPost.sns_type}: ${itemPost.post_id}`}</span>
                          </div>
                        ))
                      }
                      {
                        item.condition?.gbp_posts &&
                        item.condition.gbp_posts.map((itemGbpPost: IGbpPost, indexGbpPost: number) => (
                          <div className="bottom-txt" key={indexGbpPost}>
                            <i className='points'></i>
                            <span className='txt bold'>{`[${itemGbpPost.post_id}]`}</span>
                          </div>
                        ))
                      }
                      {
                        item.condition?.posts &&
                        item.condition.posts.map((itemPost: IPost, indexPost: number) => (
                          <div className="bottom-txt" key={indexPost}>
                            <i className='points'></i>
                            <span className='txt bold'>{`[${itemPost.post_id}: ${itemPost.sns_type}]`}</span>
                          </div>
                        ))
                      }
                    </div>
                    <div className="bottom-btn">
                      <a href="#javascript" className='btn btn-border lang-tag not-clickable'>{item.lang}</a>
                    </div>
                  </div>
                </div>
                <div className="col-td width14 font14">
                  <div className="spacing">
                    <span className="time">
                      {(item?.condition?.start_date && item?.condition?.end_date) ? (<>
                        {format(utcToZonedTime(new Date(item?.condition?.start_date), currentTimeZone), DATE_FORMAT)}
                       <div className='period-tag'>|</div>
                      {format(utcToZonedTime(new Date(item?.condition?.end_date), currentTimeZone), DATE_FORMAT)}</>) : ''  }
                    </span>
                  </div>
                </div>
                <div className="col-td width14">
                  <div className="spacing">
                    <span className="time">
                      {format(utcToZonedTime(new Date(item?.created_at), currentTimeZone), DATE_FORMAT)}
                    </span>
                  </div>
                </div>
                <div className="col-td width14 font14">
                  <div className="spacing">
                    <span className="time">
                      {(item?.status === 'completed' || item?.status === 'error') && item?.completed_at ? format(utcToZonedTime(new Date(item?.completed_at),currentTimeZone),DATE_FORMAT) : ''}
                    </span>
                  </div>
                </div>
                <div className='col-td width11'>
                  <div className="spacing">
                    <span >
                      {(item?.status === 'completed' ? item?.number_of_reviews : '')}
                    </span>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      
      <Pagination
        filterFormData={filterFormData}
        totalRowsNumber={totalCount}
        onChangeFilterFormData={(filterFormData: IAnalysesFilterFormData) => {
          onChangeFilterFormData(filterFormData)
        }}
        />
    </>
  );
};

export default AnalysesList;
