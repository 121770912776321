import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

export interface IAuth0ProviderWithNavigationProps {
  children: any;
  domain: string;
  clientId: string;
  redirectUri: string;
}

const Auth0ProviderWithNavigation: React.FunctionComponent<IAuth0ProviderWithNavigationProps> = ({ children, domain, clientId, redirectUri, ...args }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  }

  return (
    <Auth0Provider domain={domain} clientId={clientId} authorizationParams={{redirect_uri: redirectUri}} onRedirectCallback={onRedirectCallback} {...args}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigation;