export interface ICategory {
    id: number;
    name: string;
    checked?: boolean;
  }

// Use this function to do custom sorting for display of conditions of any category.
export const sortCategories = (items: ICategory[]): ICategory[] => {
  const othersIndex = items.findIndex(
    item => item?.name === 'その他')
  ;
  if(!othersIndex || othersIndex === -1) { return items }

  const itemsCopy = [...items];
  const others = itemsCopy.splice(othersIndex, 1)[0];
  itemsCopy.push(others);

  return itemsCopy;
};