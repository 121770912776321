import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import DataSvc from '../../services/dataSvc';

export function ProtectedRoute({ component, ...args }: any) {
  const [tokenFetched, setTokenFetched] = useState(false);
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      DataSvc.setToken(getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'read:current_user'
        }
      }).then(token => {
        setTokenFetched(true);
        return token || '';
      }));
    }
  }, [isAuthenticated, getAccessTokenSilently])

  if (!isAuthenticated || tokenFetched) {
    const Component = withAuthenticationRequired(component, {});
    return (
      <Component />
    );
  } else {
    return <div>loading</div>
  }

}