/* eslint-disable jsx-a11y/anchor-is-valid */
import {useTranslation} from 'react-i18next';
import React, {MutableRefObject, useRef, useState} from 'react';

import './styles.scss';
import { ModalConfirm } from '../../ModalConfirm';
import { ICategory } from '../../../models/ICategory';
import { SnsType } from '../../../enums/enums';
import DropdownSelect from '../../FormElement/DropdownSelect';
import
{
  ILocation,
  ISnsType,
} from '../../../models';

export interface IModalNewAnalysisProps {
  onClose: () => void;
  onSave: (selectedSnsType: SnsType, formData: FormData) => void;
}

export const ModalSnsAnalysis: React.FunctionComponent<IModalNewAnalysisProps> = (props) => {
  const { onClose, onSave } = props;
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`modalSnsAnalysis.${key}`)

  const snsTypeList = Object.values(SnsType).map((item) => {
    return ({ id: item, name: item })
  })

  const csvInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)
  const [selectedSnsType, setSelectedSnsType] = useState<ISnsType>(snsTypeList[0])
  const [selectedCsvFile, setSelectedCsvFile] = useState<Blob | null>(null)
  const [selectedCsvFileName, setSelectedCsvFileName] = useState<string>('')
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);

  const checkIsCorrectSnsName = (fileName: string) => {
    const fileNameSplit = fileName.split('_')
    return selectedSnsType.name === fileNameSplit[0]
  }

  const onSelectedCsvFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = e.currentTarget.files;
    if(files === null) {
      return
    }
    if(!checkIsCorrectSnsName(files[0].name)) {
      alert(`File Name is not correct, should be ${selectedSnsType.name}_xxxxxxxxx.csv`)
      return
    }

    setSelectedCsvFile(files[0])
    setSelectedCsvFileName(files[0].name)
  }

  return (    
    <div className="modal modal-default modal-new-analysis">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="top-title flex-grid">
          {t('new_sns_analysis')}
        </div>

        <div className="title-bar row-form mb60">
          <div className="label-title flex">
            {t('sns_type')}
          </div>
          <DropdownSelect
            fieldLabel={''}
            value={selectedSnsType}
            isTranslation={false}
            items={snsTypeList}
            placeholder={'select_a_single_location'}
            classNameContainer={'sort-dropdown'}
            onChange={(
              snsType: ILocation | ISnsType | ICategory
            ) => {
              if(snsType.id !== selectedSnsType.id) {
                setSelectedCsvFile(null)
                setSelectedCsvFileName('')
              }
              setSelectedSnsType(snsType as ISnsType)
            }}
          />
          <div className="right-btn input-file-container">
            <a
              className='btn btn-border'
              onClick={() => {
                if(csvInputRef) {
                  csvInputRef.current?.click();
                }
              }}>
              { !selectedCsvFile && <i className='icons icon-add'></i> }
              { selectedCsvFile ? selectedCsvFileName : t('select_file')}
            </a>
            <input onChange={onSelectedCsvFile}  ref={csvInputRef} type='file' id="csv-file" style={{ display: 'none' }}  placeholder="CSV" accept=".csv"/>
          </div>
        </div>

        <div className="bottom-btns">
          <a className='btn btn-border'
            onClick={() => {
              onClose()
            }}>
            {t('cancel')}
          </a>
          <a className={`btn btn-blue ${!selectedCsvFile ? 'disabled' : ''}`}
            onClick={() => {
              setShownModalConfirm(true);
            }}>
            {t('start')}
          </a>
        </div>
      </div>

      {shownModalConfirm && (        
        <ModalConfirm
          title={'please_confirm_to_add_new_sns_analysis'}
          cancelLabel={'cancel'}
          confirmLabel={'confirm'}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            if(selectedCsvFile !== null) {
              const formData  = new FormData();
              formData.append('file', selectedCsvFile);
              onSave(selectedSnsType.name as SnsType, formData)
            }
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalSnsAnalysis;
