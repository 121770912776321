import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import nprogress from 'accessible-nprogress';
import dayjs from 'dayjs';

import DataSvc from '../../services/dataSvc';
import dataAction from '../../actions/dataAction';
import { delay } from '../../utils';
import Header from '../../components/Header';
import Overview from '../../components/AnalysisResultComponents/Overview';
import Stats from '../../components/AnalysisResultComponents/Stats';
import Wordcloud from '../../components/AnalysisResultComponents/Wordcloud';
import NLPAnalysis from '../../components/AnalysisResultComponents/NLPAnalysis';
import { Language, SnsType } from '../../enums/enums';

import './styles.scss';
import Footer from '../../components/Footer';

interface IAnalysisResultPageProps {
  // organization?: OrganizationResponse;
  dataAction?: any;
}

const AnalysisResultPage: React.FunctionComponent<IAnalysisResultPageProps> = (
  props,
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`analyseResultPage.${key}`);

  const params = useParams();
  const uuid = params.uuid || '';

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [categoryType, setCategoryType] = useState<string>('rating'); // 'rating', 'ai'
  const [criteriaType, setCriteriaType] = useState<string>('all'); // 'all', '1to3', '4to5', 'good', 'bad'

  useEffect(() => {
    nprogress.configure({ parent: 'main' });
    nprogress.start();
  }, [props.dataAction]);

  const { data: analysisOverview } = useQuery(
    ['analysisOverview'],
    async () => {
      try {
        const res = await DataSvc.getAnalysisOverviewData(uuid);
        return res;
      } catch (e) {
        toast.error(
          `${_t('error.fetching_analysis_overview')}: \n${e?.toString()}`,
          {
            autoClose: 5000,
          },
        );

        await delay(5000);
        return null;
      }
    },
  );

  const { data: analysisResult } = useQuery(
    ['analysisResult', categoryType, criteriaType],
    async () => {
      try {
        nprogress.start();
        const res = await DataSvc.getAnalysisResultData(
          uuid,
          categoryType,
          criteriaType,
        );
        nprogress.done();

        setTimeout(() => {
          if (!isFirstLoad) {
            const element = document.getElementById('result-nlp-analysis');
            element?.scrollIntoView({ behavior: 'auto', inline: 'nearest' });
          }
          setIsFirstLoad(false);
        }, 100);

        return res;
      } catch (e) {
        toast.error(
          `${_t('error.fetching_analysis_result')}: \n${e?.toString()}`,
          {
            autoClose: 5000,
          },
        );

        await delay(5000);
        return null;
      }
    },
  );

  const { refetch: queryProcessedReviews } = useQuery(
    ['processedReviews', analysisOverview],
    async () => {
      try {
        const is_sns = analysisOverview?.condition?.locations
          ? 'false'
          : 'true';
        const sns_type =
          is_sns === 'true'
            ? analysisOverview?.condition?.gbp_posts
              ? SnsType.GBP
              : analysisOverview?.condition?.posts[0].sns_type
            : null;

        let ids = [];
        if (is_sns === 'true') {
          if (sns_type === SnsType.GBP) {
            ids =
              analysisOverview?.condition?.gbp_posts.map(
                (post: any) => post.post_id,
              ) || [];
          } else {
            ids =
              analysisOverview?.condition?.posts.map(
                (post: any) => post.post_id,
              ) || [];
          }
        } else {
          ids =
            analysisOverview?.condition?.locations.map(
              (location: any) => location.id,
            ) || [];
        }

        const startDate =
          (!!analysisOverview?.condition?.start_date &&
            dayjs(analysisOverview?.condition?.start_date).format(
              'YYYY-MM-DD',
            )) ||
          '2011-01-01';
        const endDate =
          (!!analysisOverview?.condition?.end_date &&
            dayjs(analysisOverview?.condition?.end_date).format(
              'YYYY-MM-DD',
            )) ||
          dayjs(analysisOverview?.queued_at).format('YYYY-MM-DD');

        const res = await DataSvc.getProcessedReviewsData({
          start_date: startDate,
          end_date: endDate,
          ...(analysisOverview?.lang && {
            language: analysisOverview.lang,
          }),
          sns_type: sns_type,
          is_sns: is_sns,
          ids: ids,
        });
        return res;
      } catch (e) {
        toast.error(
          `${_t('error.fetching_processed_reviews')}: \n${e?.toString()}`,
          {
            autoClose: 5000,
          },
        );

        await delay(5000);
        return null;
      }
    },
    {
      enabled: false,
    },
  );

  const { data: analysisStats } = useQuery(['analysisStats'], async () => {
    try {
      return await DataSvc.getAnalysisStatsData(uuid);
    } catch (e) {
      toast.error(
        `${_t('error.fetching_analysis_stats')}: \n${e?.toString()}`,
        {
          autoClose: 5000,
        },
      );

      await delay(5000);
      return null;
    }
  });

  const { data: analysisWordcloud } = useQuery(
    ['analysisWordcloud'],
    async () => {
      try {
        return await DataSvc.getAnalysisWordcloudData(uuid);
      } catch (e) {
        toast.error(
          `${_t('error.fetching_analysis_wordcloud')}: \n${e?.toString()}`,
          {
            autoClose: 5000,
          },
        );

        await delay(5000);
        return null;
      }
    },
  );

  const getCsvTitle = () => {
    const locationName = analysisOverview?.condition?.locations
      ? analysisOverview?.condition?.locations
          .map((item: any) => {
            return item.name;
          })
          .join('_') + '_'
      : '';
    const startDate =
      (!!analysisOverview?.condition?.start_date &&
        dayjs(analysisOverview?.condition?.start_date).format('YYYY-MM-DD') +
          '_') ||
      '';
    const endDate =
      (!!analysisOverview?.condition?.end_date &&
        dayjs(analysisOverview?.condition?.end_date).format('YYYY-MM-DD') +
          '_') ||
      '';
    const lang = analysisOverview?.lang || Language.ALL;

    return locationName + startDate + endDate + lang;
  };

  const onClickDownload = async () => {
    const result = await queryProcessedReviews();
    if (result.status === 'success') {
      let csv = 'data:text/csv;charset=utf-8,';

      let csvContent = 'review_date,rating,comment\n';

      result.data.forEach((item: any) => {
        const {
          review_date,
          rating = null,
          star_rating = null,
          comment,
        } = item;
        const ratingValue = rating || star_rating || '-';
        csvContent += `${dayjs(review_date).format(
          'YYYY-MM-DD',
        )},${ratingValue},${comment}\n`;
      });

      csv += csvContent;
      const encodedUri = encodeURI(csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${getCsvTitle()}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  nprogress.done();

  return (
    <React.Fragment>
      <Header />

      <ToastContainer />

      <div className="analyses-result-page">
        <div className="top-back flex">
          <NavLink to={'/analyses'} className="icons icon-back">
            {t('return_to_analyses')}
          </NavLink>
        </div>

        {!!analysisOverview && (
          <Overview
            analysisOverview={analysisOverview}
            onClickDownload={onClickDownload}
          />
        )}

        <Stats analysisStats={analysisStats} />

        {!!analysisWordcloud && (
          <Wordcloud analysisWordcloud={analysisWordcloud} />
        )}

        {analysisResult && (
          <NLPAnalysis
            categoryType={categoryType}
            criteriaType={criteriaType}
            analysisResult={analysisResult}
            onClickTabs={(categoryType: string, criteriaType: string) => {
              setCategoryType(categoryType);
              setCriteriaType(criteriaType);
            }}
          />
        )}
      </div>

      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({ ...state.dataReducer });

const matchDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch),
  dataAction: bindActionCreators({ ...dataAction }, dispatch),
});

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(AnalysisResultPage);
