/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';

export interface IModalConfirmProps {
  title: string;
  description?: string;
  secondaryDescription?: string;
  cancelLabel: string;
  confirmLabel: string;
  onClose: () => void
  onConfirm: () => void
}

export const ModalConfirm: React.FunctionComponent<IModalConfirmProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`modalConfirm.${key}`)

  const { title, description, secondaryDescription, cancelLabel, confirmLabel, onClose, onConfirm } = props;

  return (    
    <div className="modal-default modal-confirm">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="modal-mains__main">
          <div className="top-title">
            {t(title)}
          </div>
          
          {(description || secondaryDescription) && (
            <div className="description-txt">
              {description ? t(description) : null }
              {secondaryDescription ? secondaryDescription : null}
            </div>
          )}
          
          <div className="bottom-btns">
            {cancelLabel !== '' && (
              <a className='btn btn-border'
                onClick={() => {
                  onClose()
                }}>
                {t(cancelLabel)}
              </a>
            )}
            
            <a className='btn btn-blue'
              onClick={() => {
                onConfirm()
              }}>
              {t(confirmLabel)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
