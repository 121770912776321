import React, {ChangeEvent, KeyboardEvent, FocusEvent} from 'react';
import './styles.scss';

export interface IInputBoxProps {
  value: string;
  classNameContainer?: string;
  pattern?: string;
  placeholder?: string;
  isSearch?: boolean;
  errorLabel?: string;
  type?: string;
  isFocused?: boolean,
  name?: string,
  onChange: (value: string) => void;
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLImageElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const InputBox: React.FunctionComponent<IInputBoxProps> = (props) => {
  const { value, classNameContainer, pattern, placeholder, isSearch, errorLabel, isFocused, name,
          onChange, onChangeEvent, onKeyPress, onFocus, onBlur, type='text' } = props;

  return (
    <div className={`inputs
                     ${classNameContainer ? classNameContainer : ''}
                     ${isSearch ? 'search-box' : ''}
                     ${errorLabel ? 'error-txt' : ''}
                     ${isFocused  ? 'focused' : ''}`}>
      {isSearch && (
        <a href="#javascript" className='icons icon-search'>
        </a>
      )}
      <input type={type}
        placeholder={placeholder || ''}
        value={value}
        pattern={pattern}
        { ...(name ? { name }: {}) }
        onChange={(
          evt: ChangeEvent<HTMLInputElement>,
        ) => {
          onChange(evt.target.value)
          if (onChangeEvent) {
            onChangeEvent(evt)
          }
        }}
        onKeyPress={(
          evt: any,
        ) => {
          if (onKeyPress) {
            onKeyPress(evt)
          }
        }}

        onFocus={(evt: any) => {
          if (onFocus) {
            onFocus(evt)
          }
        }}
        onBlur={(evt: any) => {
          if (onBlur) {
            onBlur(evt)
          }
        }}
      />
      {errorLabel && (
        <div className="error">{errorLabel}</div>
      )}
    </div>
  );
};

export default InputBox;
