import axios from 'axios';
import { API_BASE } from '../config';
import { IMunicipality } from '../models/';
import { ICategory } from '../models/ICategory';

export const ERR_TOKEN_IS_NOT_READY = 'ERR_TOKEN_IS_NOT_READY';

const axiosInstance = axios.create({
  baseURL: API_BASE,
});

axiosInstance.interceptors.request.use((config) => {
  if (!DataSvc.getToken()) {
    throw Error(ERR_TOKEN_IS_NOT_READY);
  }

  return DataSvc.getToken().then((token) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
});

// data service
export default class DataSvc {
  static progressCount = 0;
  static token: Promise<string>;
  static setToken(token: Promise<string>) {
    DataSvc.token = token;
  }

  static getToken() {
    return DataSvc.token;
  }

  static async getAnalysesData(offset: number, limit: number) {  
    const response = await axiosInstance.get(`/analyses?offset=${offset}&limit=${limit}`);
    return response.data;
  }
  
  static async getLocationsData( category_id: number[]) {
    const response = await axiosInstance.get(`/locations`, {
      params: {
        ...(category_id &&
          category_id.length && { category_id }),
        limit: Number.MAX_SAFE_INTEGER // temporary change to allow business to access all locations
      }
    });

    return response.data;
  }

  static async getCategoriesData(): Promise<ICategory[]> {
    const response = await axiosInstance.get('/categories');
    return response.data.data;
  }

  static async getMunicipalitiesData(): Promise<IMunicipality[]> {
    const response = await axiosInstance.get('/municipalities');
    return response.data.data;
  }
  
  static async getAnalysesStatusData(uuidsArray: string[]) {
    let url = '/analyses/status';
    (uuidsArray || []).forEach((uuid: string, index: number) => {
      url += `${index === 0 ? '?' : '&'}uuids=${uuid}`;
    });
    
    const response = await axiosInstance.get(url);
    return response.data;
  }

  static async getProcessedReviewsData(params: any) {
    const response = await axiosInstance.get('/analysis-result/processed-reviews', { params } );
    return response.data;
  }
  
  static async getAnalysisOverviewData(uuid: string) {  
    const response = await axiosInstance.get(`/analysis-overview?uuid=${uuid}`);
    return response.data;
  }
  
  static async getAnalysisResultData(uuid: string, category: string, criteria: string) {  
    const response = await axiosInstance.get(`/analysis-result?uuid=${uuid}&category=${category}&criteria=${criteria}`);
    return response.data;
  }
  
  static async getAnalysisStatsData(uuid: string) {  
    const response = await axiosInstance.get(`/analysis-stats?uuid=${uuid}`);
    return response.data;
  }
  
  static async getAnalysisWordcloudData(uuid: string) {  
    const response = await axiosInstance.get(`/analysis-wordcloud?uuid=${uuid}`);
    return response.data;
  }
  
  // create analysis
  static async postAnalysesData(payload: any) {
    const response = await axiosInstance.post('/analyses', payload, { headers: { 'Content-Type': 'application/json' } });
    
    // console.log(response);
    return response.data;
  }

  static async postSnsAnalysesData(formData: any) {
    const response = await axiosInstance.post(`/analyses/sns`, formData);
    return response.data;
  }

  static async postGbpAnalysesData(formData: any) {
    const response = await axiosInstance.post(`/analyses/gbp`, formData);
    return response.data;
  }
}
