import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';

export interface IStatusItemProps {
  status: string;
}

export const StatusItem: React.FunctionComponent<IStatusItemProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`common.status.${key}`)
  
  const { status } = props;
  
  // get Status Div
  const getStatusDiv = () => {
    switch (status) {
      case 'completed':
        return <div className="status-wrap">
                 <i className='icons icon-done'></i> 
                 <span className='txt green'>{t('complete')}</span>
               </div>;
      case 'error':
        return <div className="status-wrap">
                 <i className='icons icon-error'></i> 
                 <span className='txt red'>{t('error')}</span>
               </div>;
      case 'waiting':
        return <div className="status-wrap">
                 <i className='icons icon-wait'></i> 
                 <span className='txt gray'>{t('waiting')}</span>
               </div>;
      case 'processing':
        return <div className="status-wrap">
                 <i className='icons icon-process'></i> 
                 <span className='txt orange'>{t('processing')}</span>
               </div>;
    }
  }

  return (    
    <>
      {getStatusDiv()}
    </>
  );
};

export default StatusItem;
