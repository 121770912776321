/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import
{
  IResultRelatedWords,
  IResultKeywords,
  IResult,
} from '../../../models';
import './styles.scss';

export interface INLPAnalysisProps {
  categoryType: string;
  criteriaType: string;
  analysisResult: IResult;
  onClickTabs: (categoryType: string, criteriaType: string) => void;
}

export const NLPAnalysis: React.FunctionComponent<INLPAnalysisProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`analyseResultPage.nlp_analysis.${key}`)
  
  const { categoryType, criteriaType, analysisResult, onClickTabs } = props;
  
  useEffect(() => {
    onClickTabs(categoryType, criteriaType);
  }, [categoryType, criteriaType, onClickTabs]);

  return (
    <div className="result-nlp-analysis" id="result-nlp-analysis">
      <h4 className='title'>
        {t('nlp_analysis')}
        <span className="line"></span>
      </h4>
      <div className="nlp-choose">
        <div className="row-group flex">
          <div className="titles">
            {t('category')}
          </div>
          <ul className="tab-btns flex">
            <li>
              <a className={`btn btn-border ${categoryType === 'rating' ? 'current' : ''}`}
                onClick={(event) => {
                  onClickTabs('rating', 'all');
                  event.preventDefault();
                }}>
                {t('rating')}
              </a>
            </li>
            <li>
              <a className={`btn btn-border ${categoryType === 'ai' ? 'current' : ''}`}
                onClick={(event) => {
                  onClickTabs('ai', 'all');
                  event.preventDefault();
                }}>
                {t('ai')}
              </a>
            </li>
          </ul>
        </div>
        <div className="row-group flex">
          <div className="titles">
            {t('criteria')}
          </div>
          {categoryType === 'rating' && (
            <ul className="tab-btns flex">
              <li>
                <a className={`btn btn-border min137 ${criteriaType === 'all' ? 'current' : ''}`}
                  onClick={(event) => {
                    onClickTabs(categoryType, 'all');
                    event.preventDefault();
                  }}>
                  {t('all')}
                </a>
              </li>
              {
                analysisResult.hasStarRatingData &&
                <li>
                  <a className={`btn btn-border min137 ${criteriaType === '1to3' ? 'current' : ''}`}
                    onClick={(event) => {
                      onClickTabs(categoryType, '1to3');
                      event.preventDefault();
                    }}>
                    {t('1_to_3_stars')}
                  </a>
                </li>
              }
              {
                analysisResult.hasStarRatingData &&
                <li>
                  <a className={`btn btn-border min137 ${criteriaType === '4to5' ? 'current' : ''}`}
                    onClick={(event) => {
                      onClickTabs(categoryType, '4to5');
                      event.preventDefault();
                    }}>
                    {t('4_to_5_stars')}
                  </a>
                </li>
              }
            </ul>
          )}
          {categoryType === 'ai' && (
            <ul className="tab-btns flex">
              <li>
                <a className={`btn btn-border min137 ${criteriaType === 'all' ? 'current' : ''}`}
                  onClick={(event) => {
                    onClickTabs(categoryType, 'all');
                    event.preventDefault();
                  }}>
                  {t('all')}
                </a>
              </li>
              <li>
                <a className={`btn btn-border min137 ${criteriaType === 'good' ? 'current' : ''}`}
                  onClick={(event) => {
                    onClickTabs(categoryType, 'good');
                    event.preventDefault();
                  }}>
                  {t('good')}
                </a>
              </li>
              <li>
                <a className={`btn btn-border min137 ${criteriaType === 'bad' ? 'current' : ''}`}
                  onClick={(event) => {
                    onClickTabs(categoryType, 'bad');
                    event.preventDefault();
                  }}>
                  {t('bad')}
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="number-of-wrap">
        {categoryType === 'rating' && (
          <div className="table-title">
            {t('number_of_reviews')}: <span className='number'>{analysisResult.number_of_reviews}</span> 
          </div>
        )}
        {categoryType === 'ai' && (
          <div className="table-title">
            {t('number_of_sentence')}: <span className='number'>{analysisResult.number_of_reviews}</span> 
          </div>
        )}
        <div className="white-border-panel">      
        <div className="table-data">
          <div className="row-th">
            <div className="col-th width25">
              <div className="spacing">
                {t('keyword')}
              </div>
            </div>
            <div className="col-th width11">
              <div className="spacing">
                {t('appearance')}
              </div>
            </div>
            <div className="col-th width21 ">
              <div className="spacing">
                {t('related')}
              </div>
            </div>
            <div className="col-th width44 ">
              <div className="spacing">
                {t('sample_review')}
              </div>
            </div>
          </div>
          {
            analysisResult.keywords.map((item: IResultKeywords, index: number) => (
              <div className="row-td" key={index}>
                <div className="col-td width25">
                  <div className="spacing bold">
                    {item.word}
                  </div>
                </div>
                <div className="col-td width11">
                  <div className="spacing font17">
                    {item.count}
                  </div>
                </div>
                <div className="col-td width21">
                  <div className="spacing">
                    <ul className='num-list'>
                      {
                        item.related_words.map((itemWord: IResultRelatedWords, indexWord: number) => (
                          <li key={indexWord}>
                            <span className='num'>{itemWord.rank}.</span>
                            <span className='txt'>
                              {itemWord.word}
                            </span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                <div className="col-td width44 ">
                  {
                    item.sample_reviews.map((itemReview: string, indexReview: number) => (
                      <div className="spacing flex" key={indexReview}>
                        <span className='points'></span>
                        <p className='p-txt' dangerouslySetInnerHTML={{ __html: itemReview }}>
                        </p>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
      </div>
    </div>
  );
};

export default NLPAnalysis;
