export enum BoolEnum {
  TRUE = 'true',
  FALSE = 'false',
}

export enum SnsType {
  IG = 'ig',
  TWITTER = 'twitter',
  GBP = 'gbp',
}

export enum Language {
  JA = 'ja',
  EN = 'en',
  ALL = 'all',
}