import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Legend,
  CartesianGrid,
  Tooltip,
  Line,
  XAxis,
  YAxis } from 'recharts';
import { OrgDailyMetricsSettingData, OrgDailyMetricsData } from '../../../../models/MetricsLineChartData';
import * as _ from 'lodash';
import './styles.scss';

export interface ILineChartItemProps {
  width: number;
  height: number;
  dataList: OrgDailyMetricsData;
}

export const LineChartItem: React.FunctionComponent<ILineChartItemProps> = (props) => {
  const { width, height, dataList } = props;
  
  const [dataListShown, setDataListShown] = useState<OrgDailyMetricsData>();
  
  useEffect(() => {
    if (dataList) {
      const dataListTemp = dataList;
      setDataListShown(_.cloneDeep(dataListTemp));
    }    
  }, [dataList]);
    
  return (
    <>
      {!!dataListShown && (
        <LineChart width={width} height={height} data={dataListShown.dataForLine}>
          <Legend 
            margin={{top: 0, left: 0, right: 0, bottom: 0}}
            iconType="plainline"
            wrapperStyle={{fontSize: "12px", fontWeight: 700}} />
          <CartesianGrid strokeDasharray="3 2" />
          <XAxis dataKey="label" angle={-45} textAnchor="end" interval={0} tick={{fontSize: 13}} style={{ fontFamily: 'Inter' }}
            tickFormatter={(value: any, index: number) => (value || '').replace('20', '')} />
          <YAxis tick={{fontSize: 13}} style={{ fontFamily: 'Inter' }}
            tickFormatter={(value: number) => new Intl.NumberFormat('en').format(value)} />
          <Tooltip />
          {
            dataListShown.dataSources.map((item: OrgDailyMetricsSettingData, index: number) => (
              <React.Fragment key={index}>
                <Line
                  type="linear"
                  name={item.label}
                  dataKey={item.fieldName}
                  stroke={item.color}
                  strokeWidth={4}
                  dot={false}
                  />
              </React.Fragment>
            ))
          }
        </LineChart>
      )}
    </>
  );
};

export default LineChartItem;
