/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useTranslation} from 'react-i18next';
import './styles.scss';

export interface IFooterProps {
}

export const Footer: React.FunctionComponent<IFooterProps> = () => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`footer.${key}`)

  return (
    <footer className='footer'>
      <p>{t('monohojo')}</p>
      <p>
        <small>
          {t('copyright')}
        </small>
      </p>
    </footer>
  );
};

export default Footer;
