import React from 'react';
import {useTranslation} from 'react-i18next';
import
{
  IWordCloud,
} from '../../../models';
import './styles.scss';

export interface IWordcloudProps {
  analysisWordcloud: IWordCloud;
}

export const Wordcloud: React.FunctionComponent<IWordcloudProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`analyseResultPage.${key}`)

  const { analysisWordcloud } = props;

  return (
    <div className="result-wordcloud">
      <h4 className='title'>
        {t('wordcloud')}
        <span className="line"></span>
      </h4>
      <div className="wordcloud-content">
        {analysisWordcloud?.url && (
          <img src={analysisWordcloud.url} alt="img" />
        )}
      </div>
    </div>
  );
};

export default Wordcloud;
