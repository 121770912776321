import React, {useEffect, useState, ChangeEvent, KeyboardEvent} from 'react';
import { InputBox } from '../FormElement/InputBox';
import formValidationSvc from '../../services/formValidationSvc';
import
{
  IAnalysesFilterFormData,
} from '../../models';
import './styles.scss';
import _ from 'lodash';

export interface IPaginationProps {
  filterFormData: IAnalysesFilterFormData;
  totalRowsNumber: number;
  onChangeFilterFormData: (filterFormData: IAnalysesFilterFormData) => void
}

export const Pagination: React.FunctionComponent<IPaginationProps> = (props) => {
  const { filterFormData, totalRowsNumber, onChangeFilterFormData } = props;
  
  const [pageIndex, setPageIndex] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  
  useEffect(() => {
    if (filterFormData) {
      setPageIndex(filterFormData.pageIndex.toString())
    }
  }, [filterFormData]);
  
  // get Total Pages
  const getTotalPages = () => {
    const total = Math.ceil(totalRowsNumber / filterFormData.numberPerPage);
    return _.isNaN(total) ? 1 : total;
  }

  return (    
    <div className="pagenation">
      <div className="center-page">
        <button
          className={`icons btn-prev ${filterFormData.pageIndex <= 1 ? 'disabled' : ''}`}
          onClick={() => {
            onChangeFilterFormData({
              ...filterFormData,
              pageIndex: filterFormData.pageIndex - 1
            })
          }}>
        </button>
        <div className="page-area flex">
          <InputBox
            value={pageIndex}
            pattern="[1-9][0-9]{0,9}"
            placeholder={'1'}
            classNameContainer={'number-input'}
            isFocused={isFocused}
            onChange = {(value: string) => {
            }}
            onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
              setPageIndex(formValidationSvc.validateInputEnteringPattern(event, pageIndex))
            }}
            onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
              if(event.key === 'Enter'){
                if (!pageIndex || Number(pageIndex) > getTotalPages()) {
                  return;
                }
                onChangeFilterFormData({
                  ...filterFormData,
                  pageIndex: parseInt(pageIndex)
                })
              }
            }}
            onFocus={() => { setIsFocused(true) }}
            onBlur={() => { setIsFocused(false) }}
            />
          <div className="total-num">
            /   {getTotalPages() <= 0 ? 1 : getTotalPages() }
          </div>
        </div>
        <button
          className={`icons btn-next ${filterFormData.pageIndex >= getTotalPages() ? 'disabled' : ''}`}
          onClick={() => {
            onChangeFilterFormData({
              ...filterFormData,
              pageIndex: filterFormData.pageIndex + 1
            })
          }}>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
